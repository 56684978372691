<script setup>
const properties = defineProps({
  title: {
    type: String,
    default: ''
  },
  buttonText: {
    type: String,
    default: 'mehr erfahren'
  },
  route: {
    type: Object,
    default: () => ({
      path: '/',
      extern: false
    })
  },
  text: {
    type: String,
    default:
      'lorem ipsum dolor sit amet consectetur adipisicing elit. Sit, amet. Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit, amet.'
  }
})
</script>

<template>
  <v-card
    :elevation="10"
    min-height="320px"
    color="grey-lighten-3"
    class="d-flex flex-column h-100 px-3 py-5 pb-md-5"
  >
    <v-row class="ma-4">
      <v-col cols="12">
        <h3 class="text-h6 text-uppercase hyphens">{{ properties.title }}</h3>
        <p class="font-body-1 pt-5">{{ properties.text }}</p>
      </v-col>
      <v-col cols="12"> </v-col>
      <v-col cols="12">
        <div class="d-flex align-end h-100">
          <v-btn
            aria-label="Mehr erfahren"
            variant="flat"
            color="primary"
            dark
            @click="navigateTo(properties.route)"
          >
            {{ properties.buttonText }}
            <Icon class="ml-1" size="1.5em" name="system-uicons:arrow-right-circle" />
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
